










  import { Component, Prop, Vue } from 'vue-property-decorator'
  @Component
  export default class OneSidebar extends Vue {
    @Prop({
      type: Boolean,
      default: false,
    })
    showActionMenu!: boolean;
  }
